import React, { Fragment, useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { postDataAndImage, postData, getData } from '../FetchServices';
import { Select, Checkbox, } from '@material-ui/core';
import Selects from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from './Header';
import Footer from './Footer';
import swal from "@sweetalert/with-react"

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput
} from "mdbreact";
import color from '@material-ui/core/colors/amber';


const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: 'row',

  },
  newbtn: {
    border: 'none',
    position: 'absolute',
    right: '5',
    top: '5',
    minHeight: 50,
    background: '#3f8ccb none repeat scroll 0 0',
    padding: '0 30',
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
  },

  formControl: {
    margin: 0,
    minWidth: '100%',
    fontSize: 12,

  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: 'center',
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: 'center',
    marginTop: "10px",
  },
  Title: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10',
    marginBottom: '2em',
    backgroundColor: '#000',
    color: '#fff',


  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: '100%',

  },
  bgdark: {
    backgroundColor: '#3f8ccb',
  },
  day: {
    "&:hover": {
      background: "#efefef"
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc"
    }
  },
}));

export default function Webinar(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [emailId, setEmailId] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [verifyPassword, setVerifyPassword] = React.useState('')
  const [messages, setMessages] = React.useState('')
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState('')
  const [getAllWebinars, setAllWebinars] = useState([]);
  const [min,setMin]=useState(1) 
  const [max,setMax]=useState(10)


  const handleplusten=()=>{
    setMin(min+10)
    setMax(max+10)
    
  }

  const handleminusten=()=>{
    if(min>10){
    setMin(min-10)
    setMax(max-10)
    }   
  }

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]))
    setPicture(event.target.files[0])
  }

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value)
    if (password === e.target.value) {
      setStatus("Password Matched")
    }
    else {
      setStatus("Password not matched")
    }
  }


  // fetch upcoming webinars
  const fetAllwebinar = async () => {
    let list = await getData('webinar/fetchwebinarsData');
    setAllWebinars(list);
    console.log(list);
  }
  
  useEffect(function () {
    fetAllwebinar()
    UpcomingWabinars()
  }, [])


  var WebImage = ['/images/1.jpg', '/images/2.jpg', '/images/3.jpg', '/images/4.jpg','/images/1.jpg'];

// upcoming wabinar code
 const UpcomingWabinars = ()=>{
       var counter=0
    return(<>
        {getAllWebinars.map((item)=>{
          var today = new Date().valueOf();
          var upcomingdatetemp = new Date(item.datetime).valueOf();
          var upcomingdate = new Date(item.datetime);
          var Uday = upcomingdate.getDate()
          var month = ['jan', 'feb', 'mar', 'apir', 'may', 'jun', 'july', 'aug', 'sep', 'out', 'nov', 'des'];
          var fullyear = upcomingdate.getFullYear();
          var time = upcomingdate.getHours() + ":" + (upcomingdate.getMinutes() > 10 ? upcomingdate.getMinutes() : "0" + upcomingdate.getMinutes());
          if(upcomingdatetemp > today){
  counter++;
          return(
            <div class="col-md-6 col-sm-6 equal-height" style={{ height: 690 }}>
            <div class="item">
              <div class="thumb">
                <img src={WebImage[Math.floor(Math.random()*4)]} alt="Thumb" />
              </div>
              <div class="info">
                <div class="info-box">
                  <div class="date">
          <strong>{Uday}</strong> {month[upcomingdate.getMonth()]}, {fullyear}
                        </div>
                  <div class="content">
                    <h4> 
          <a href="#" style={{ fontSize: 18, }}>{item.name}</a>
                    </h4>
                    <ul>
          <li><i class="fas fa-clock"></i> {time}</li>
                      <li><i class="fas fa-map-marked-alt"></i> California, TX 70240</li>
                    </ul>
                    <p>
                      Attachment astonished to on appearance imprudence so collecting in excellence. Tiled way blind lived whose new. The for fully had she there leave merit enjoy forth.
                    </p>
                    <div className="blog-area">
                      <div className="info">
                        <div className="content" style={{ padding: 0, position: 'static' }}>
                          <a                            
                          style={{ color: '#000' }}>
                          <i className="fa fa-bookmark" style={{ color: '#000' }}>
                            </i>
                             Book Now                         
                             </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           )
          }
        })}
        <center>{counter==0 && <div style={{color:'red'}}> Data not found</div>}</center>
        </>
    )
 }
// end upcoming wabinar //


// All wabinar code //
  const Allwabinars = () => {
    var counter=0
    return (<>
     {getAllWebinars.map((item,index) => {
        var date = new Date(item.datetime);
        var day = date.getDate();
        var month = ['jan', 'feb', 'mar', 'apir', 'may', 'jun', 'july', 'aug', 'sep', 'out', 'nov', 'des'];
        var year = date.getFullYear();
        var time = date.getHours() + ":" + (date.getMinutes() > 10 ? date.getMinutes() : "0" + date.getMinutes());
    counter++;     
        return (
          index>=min&&index<=max?(
          <div class="col-md-6 col-sm-6 equal-height" style={{ height: 690 }}>
            <div class="item">
              <div class="thumb">
                <img src={WebImage[Math.floor(Math.random()*4)]} alt="Thumb" />
              </div>
              <div class="info">
                <div class="info-box">
                  <div class="date">
                    <strong>{day}</strong> {month[date.getMonth()]}, {year}
                  </div>
                  <div class="content">
                    <h4>
                      <a href="#" style={{ fontSize: 18, }}>{item.name}</a>
                    </h4>
                    <ul>
                      <li><i class="fas fa-clock"></i> {time}</li>
                      <li><i class="fas fa-map-marked-alt"></i> California, TX 70240</li>
                    </ul>
                    <p>
                      Attachment astonished to on appearance imprudence so collecting in excellence. Tiled way blind lived whose new. The for fully had she there leave merit enjoy forth.
                            </p>
                    <div className="blog-area">
                      <div className="info">
                        <div className="content" style={{ padding: 0, position: 'static' }}>
                          <a 
                           onClick={() => {
                            swal({
                              title: "Your seat is booked for this webinar",
                              icon: "success",
                              dangerMode: true,
                            }) 
                          }}   
                          style={{ color: '#000' }}><i className="fa fa-bookmark" style={{ color: '#000' }}></i> Book Now</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           ):(<div></div>)
        )
      })}
       <center>{counter==0 && <div style={{color:'red'}}> Data not found</div>}</center>
      </>
    )
  }


  console.log("History ==  ", props)
  return (
    <Container className={classes.widths}>


      <Header />
      <CssBaseline />
      <div className="login-area default-padding" style={{ paddingTop: 20, }}>
        <div className="">
          <div className="row" >
            <div className="col-md-12">
              <div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{ backgroundImage: "url(" + "../assets/img/banner/4.jpg" + ")", padding: 70 }}>
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft" style={{ fontSize: '3rem', }}>UPCOMING WEBINARS</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div class="event-area flex-less default-padding">
                <div class="container">
                  <div class="">
                   
                    <div class="event-items">
                      {UpcomingWabinars()}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard" style={{ backgroundImage: "url(" + "../assets/img/banner/4.jpg" + ")", padding: 70 }}>
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="  " style={{ fontSize: '3rem', }}>All WEBINARS</h2>
                    </div>
                  </div>
                </div>
              </div>


              <div class="event-area flex-less default-padding">
                <div class="container">
                  <div class="">
                   
                    <div class="event-items">
                      {Allwabinars()}
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
          {/* end of row div */}

          

        </div>
      </div>

                                <div className="row" style={{marginLeft:520}}>
                                <div style={{width:170, marginBottom:50}}>
                                <a 
                                onClick={()=>handleminusten()}
                                >
                                    <MDBBtn
                                    gradient="blue"                                    
                                    rounded
                                    width='50'
                                    variant="contained"
                                    // onClick={addNewRecord}
                                    color="primary"
                                    className="btn-block z-depth-1a"
                                    // fullWidth
                                  >
                                    Previous
                                  </MDBBtn>
                                  </a>
                                  </div>
                               
                         <div style={{width:170,marginLeft:20}}>
                                <a 
                                onClick={()=>handleplusten()}
                                >
                                    <MDBBtn
                                    gradient="blue"                                    
                                    rounded                                   
                                    // marginLeft="10"
                                    variant="contained"
                                    // onClick={addNewRecord}
                                    color="primary"
                                    className="btn-block z-depth-1a"
                                    // fullWidth
                                  >
                                    Next
                                  </MDBBtn>
                                  </a>
                         </div>
                         </div>                     
                  

      <Footer />

    </Container>
  );
}
