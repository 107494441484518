/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-useless-concat */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";
import Header from "./Header";
import Footer from "./Footer";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
  addressCard: {
    maxWidth: "18rem",
  },
}));

export default function Contactus(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  //   const addNewRecord=async()=>{
  // if(picture=='')
  // {
  //     var body={'firstName':firstName,
  //     'lastName':lastName,
  //     'emailId':emailId,
  //     'password':password,
  //     'status':"Not Approved",
  //     'picture':"Not Found"}
  //     var result=await postData('selfregister/addNewRecord',body)
  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}

  // }

  // else{
  //    var formData=new FormData()
  //    formData.append('firstName',firstName)
  //    formData.append('lastName',lastName)
  //    formData.append('emailId',emailId)
  //    formData.append('password',password)
  //    formData.append('picture',picture)
  //    formData.append('status', "Not Approve")

  //    var config={headers:{'content-type':'multipart/form-data'}}
  //     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)

  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}
  //     }
  //     }

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header /> <CssBaseline />
      <div className="login-area default-padding" style={{ paddingTop: 20 }}>
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/12.jpg" + ")",
                  padding: 35,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Contact Us</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contact-info-area default-padding">
                <div className="container">
                  <div className="row contact-bottom-info">
                    <div className="maps-form">
                      <div className="col-md-6 maps">
                        <h4>Our Location</h4>
                        <div className="google-maps">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2585595.394203046!2d76.31782447631163!3d12.577280036438662!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae167ff568a92f%3A0x616a72fb191d4417!2sStudy%20Metro!5e0!3m2!1sen!2sin!4v1595407802633!5m2!1sen!2sin"
                            width="600"
                            height="450"
                            frameborder="0"
                            allowfullscreen=""
                            aria-hidden="false"
                            tabindex="0"
                          ></iframe>
                        </div>
                      </div>
                      <div className="col-md-6 form">
                        <div className="heading">
                          <h4>Contact Us</h4>
                        </div>
                        <form action="#" method="POST" className="contact-form">
                          <div className="col-md-12">
                            <div className="">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  id="name"
                                  name="name"
                                  placeholder="Name"
                                  type="text"
                                />
                                <span className="alert-error"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  placeholder="Email*"
                                  type="email"
                                />
                                <span className="alert-error"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  id="phone"
                                  name="phone"
                                  placeholder="Phone"
                                  type="text"
                                />
                                <span className="alert-error"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="">
                              <div className="form-group comments">
                                <textarea
                                  className="form-control"
                                  id="comments"
                                  name="comments"
                                  placeholder="Tell Me About Courses *"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="">
                              <button type="submit" name="submit" id="submit">
                                Send Message{" "}
                                <i className="fa fa-paper-plane"></i>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-12 alert-notification">
                            <div id="message" className="alert-msg"></div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <br />
                  {/* <div className="col-md-12 alert-notification">
                 <div id="message" className="alert-msg"></div>
            </div> */}
                  {/* hello */}

                  <div className="row">
                    {/* 1st */}
                    <div className="col-md-4">
                      <div class="card text-dark mb-3 addressCard">
                        <div class="card-header">Study Metro Bangalore</div>
                        <div class="card-body">
                          <p class="card-text">
                            2nd Floor,No. 121, Wood Street, Opposite to Brigade
                            Tower Ashoknagar Brigade Road above IBACO Bangalore
                            560025.
                          </p>
                          <p class="card-text">Tel: +91-8088-867-867,</p>
                          <p class="card-text ">Mob: +91-7722-867-867</p>
                        </div>
                      </div>
                    </div>
                    {/* 2nd */}
                    <div className="col-md-4">
                      <div class="card text-dark mb-3 addressCard">
                        <div class="card-header">Study Metro Indore</div>
                        <div class="card-body">
                          <p class="card-text">
                            517, 5th Floor, Shekhar central, AB Rd, Manorama
                            Ganj, Indore, Madhya Pradesh 452018, India.
                          </p>
                          <p class="card-text">Tel: +91-7722-867-867,</p>
                          <p class="card-text ">Mob: +91-8088-867-867</p>
                        </div>
                      </div>
                    </div>
                    {/* 3rd */}
                    <div className="col-md-4">
                      <div class="card text-dark mb-3 addressCard">
                        <div class="card-header">Study Metro Pune</div>
                        <div class="card-body">
                          <p class="card-text">
                            Office no 3, 5th floor, Bhosale Shinde Arcade, JM
                            Road, Deccan Gymkhana, Pune, Maharashtra 411004.
                          </p>
                          <p class="card-text">Tel: 7722867867,</p>
                          <p class="card-text ">Mob: 8088867867</p>
                        </div>
                      </div>
                    </div>
                    {/* 4th */}
                    <div className="col-md-4">
                      <div class="card text-dark mb-3 addressCard">
                        <div class="card-header">Study Metro Aundh</div>
                        <div class="card-body">
                          <p class="card-text">
                            Flat no 8, 3rd floor, shakun Apartments, Next to
                            Bank of Maharashtra, Near Bremen chowk, Aundh. Pune
                            - 411007.
                          </p>
                          <p class="card-text">Tel: 7722867867,</p>
                          <p class="card-text ">Mob: 8822867867</p>
                        </div>
                      </div>
                    </div>
                    {/* 5 */}
                    <div className="col-md-4">
                      <div class="card text-dark mb-3 addressCard">
                        <div class="card-header">Study Metro Jaipur</div>
                        <div class="card-body">
                          <p class="card-text">
                            411, Okay Plus, Big Benn, Swej Farm, New Sanganer
                            Road, Jaipur 302019.
                          </p>
                          <p class="card-text">Tel: 7722867867,</p>
                          <p class="card-text ">Mob: 8088867867</p>
                        </div>
                      </div>
                    </div>
                    {/* 6 */}
                    <div className="col-md-4">
                      <div class="card text-dark mb-3 addressCard">
                        <div class="card-header">Study Metro Ahmedabad</div>
                        <div class="card-body">
                          <p class="card-text">
                            203 Pramukh Plaza Complex, Opp.Ketav Petrol Pump,
                            Nr.Sbi Bank, Polytechnic Road, Ambavadi, Ahmedabad -
                            380006.
                          </p>
                          <p class="card-text">Tel: 7722867867,</p>
                          <p class="card-text ">Mob: 8088867867</p>
                        </div>
                      </div>
                    </div>
                    {/* 7 */}
                    <div className="col-md-4">
                      <div class="card text-dark mb-3 addressCard">
                        <div class="card-header">Study Metro Warangal</div>
                        <div class="card-body">
                          <p class="card-text">
                            2nd floor, beside mee- seva, nakkalagutta,
                            hanamkonda, warangal. 506001 ph : 799 326 8888,799
                            325 8888. email id : warangal@studymetro.com.
                          </p>
                          <p class="card-text">Tel: 7722867867,</p>
                          <p class="card-text ">Mob: 8088867867</p>
                        </div>
                      </div>
                    </div>
                    {/* 8 */}
                    <div className="col-md-4">
                      <div class="card text-dark mb-3 addressCard">
                        <div class="card-header">Study Metro Punjab</div>
                        <div class="card-body">
                          <p class="card-text">
                            Basement Dashmesh Palza, Near Akalsar Road, G.T Road
                            Moga 142001.
                          </p>
                          <p class="card-text">Tel: 8088867867,</p>
                          <p class="card-text ">Mob: 7722867867</p>
                        </div>
                      </div>
                    </div>
                    {/* 9 */}
                    <div className="col-md-4">
                      <div class="card text-dark mb-3 addressCard">
                        <div class="card-header">Study Metro Kerala</div>
                        <div class="card-body">
                          <p class="card-text">
                            Door No. XL/1016, 3rd Floor, Perumpillil Buildings,
                            Above Kirtilals Jewellery, Next to Maharajas Metro
                            Station, M.G. Road, Kochi Kerala, 682011.
                          </p>
                          <p class="card-text">Tel: 7722867867,</p>
                          <p class="card-text ">Mob: 8088867867</p>
                        </div>
                      </div>
                    </div>
                    {/* 10 */}
                    <div className="col-md-4">
                      <div class="card text-dark mb-3 addressCard">
                        <div class="card-header">Study Metro Gandhi Nagar</div>
                        <div class="card-body">
                          <p class="card-text">
                            Ratnaakar Nine Square, B-Wing, 8th Floor, Office
                            No.808, Opp. Keshavbaug Party Plot, Vastrapur,
                            Gujarat 380015.
                          </p>
                          <p class="card-text">Tel: 7722867867,</p>
                          <p class="card-text ">Mob: 8088867867</p>
                        </div>
                      </div>
                    </div>
                    {/* 11 */}
                    <div className="col-md-4">
                      <div class="card text-dark mb-3 addressCard">
                        <div class="card-header">Study Metro Mumbai</div>
                        <div class="card-body">
                          <p class="card-text">
                            A 717, Mastermind V Goregaon, Mumbai, Maharashtra
                            400065.
                          </p>
                          <p class="card-text">Tel: 7722867867,</p>
                          <p class="card-text ">Mob: 8088867867</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
