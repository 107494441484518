import React, {useState} from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { Switch } from "react-router-dom";
import GraduatePrograms from "./Auth/GraduatePrograms";
import UndergraduatePrograms from "./Auth/UndergraduatePrograms";
// import OnlineProgram from "./Auth/OnlineProgram";
import CertificateDiploma from "./Auth/CertificateDiploma";
import Doctoral from "./Auth/Doctoral";
import CreateAccount from "./Auth/CreateAccount";
import SchoolDetails from "./Auth/SchoolDetails";
import AboutSection from "./Auth/AboutSection";
import LandingPage from "./Auth/LandingPage";
import SearchByCheckBox from "./Auth/SearchByCheckBox";
import OnlinePayment from "./Auth/OnlinePayment";
import ApplyProgram from "./Auth/ApplyProgram";
import SearchUniversity from "./Auth/SearchUniversity";
import CompareUniversity from "./Auth/CompareUniversity";
import CompareProgram from "./Auth/CompareProgram";
import Scholarship from "./Auth/Scholarship";
import Aboutus from "./Auth/Aboutus";
import Blog from "./Auth/Blog";
import Webinar from "./Auth/Webinar";
import Contactus from "./Auth/Contactus";
import ApplyScholarship from "./Auth/ApplyScholarship";
import SearchCourse from "./Auth/SearchCourse";
import OpenBlog from "./Auth/OpenBlog";
import Login from "./Auth/Login";
import ForgotPassword from "./Auth/ForgotPassword";
import Compare from "./Auth/Compare";
import ProfileBuilder from "./Auth/ProfileBuilder";
import TermsCondition from "./Auth/TermsCondition";
import Privacy from "./Auth/Privacy";
import WhyChooses from "./Auth/WhyChooses";
import PressMedia from "./Auth/PressMedia";
import NewPaper from "./Auth/NewPaper";
import Adminssion from "./Auth/Adminssion";
import Selectuniversity from "./Auth/Selectuniversity";
import Visa from "./Auth/Visa";
import Traval from "./Auth/Traval";
// import ScholarshipShortcut from "./Auth/ScholarshipShortcut";
import InternationalSIM from "./Auth/InternationalSIM";
import UGCScholarships from "./Auth/UGCScholarships";
import Forex from "./Auth/Forex";
import Appostile from "./Auth/Appostile";
import Courier from "./Auth/Courier";
import CreditTransfer from "./Auth/CreditTransfer";
import FAQ from "./Auth/FAQ";
import General from "./Auth/General";
import UK from "./Auth/UK";
import Canada from "./Auth/Canada";
import NewZealand from "./Auth/NewZealand";
import Ireland from "./Auth/Ireland";
import France from "./Auth/France";
import Germany from "./Auth/Germany";
import Australia from "./Auth/Australia";
import Europe from "./Auth/Europe";
import USA from "./Auth/USA";
import Singapore from "./Auth/Singapore";
import Malaysia from "./Auth/Malaysia";
import Affiliates from "./Auth/Affiliates";
import AdvertiseWithUs from "./Auth/AdvertiseWithUs";
import Career from "./Auth/Career";
import Careercounseling from "./Auth/Careercounseling";
import MissionVisionValues from "./Auth/MissionVisionValues";
import TestPreparation from "./Auth/TestPreparation";
import ScholarshipFooter from "./Auth/ScholarshipFooter";
import UniversityDisplay from "./Auth/UniversityDisplay";
import CourseDiscription from "./Auth/CourseDiscription";
import SimilarProfile from "./Auth/SimilarProfile";
import IELTS from "./Auth/IELTS";
import TOEFL from "./Auth/TOEFL";
import PTE from "./Auth/PTE";
import Duolingo from "./Auth/Duolingo";
import GRE from "./Auth/GRE";
import GMAT from "./Auth/GMAT";
import SAT from "./Auth/SAT";
import ACT from "./Auth/ACT";
import PersonalOnlineMentor from "./Auth/PersonalOnlineMentor";
import CProgram from "./Auth/CProgram";
import CookiePolicy from "./Auth/CookiePolicy";
import PartnerBanks from "./Auth/PartnerBanks";
import LoanDocuments from "./Auth/LoanDocuments";
import EducationLoans from "./Auth/EducationLoans";
import Countries from "./Auth/Countries";
import LoanProvider from "./Auth/LoanProvider";
import CourseGuide from "./Auth/CourseGuide";
import Pricing from "./Auth/Pricing";
import StudyAbroadAPI from "./Auth/StudyAbroadAPI";
import StudyAbroadInsight from "./Auth/StudyAbroadInsight";
import Advising from "./Auth/Advising";
import Intake from "./Auth/Intake";
import Majors from "./Auth/Majors";
import TestPrep from "./Auth/TestPrep";
// import Checks from "./Auth/Checks";
// import StudentProfile from "./Auth/StudentDashboard";
import LandingPage2 from "./Auth/LandingPage2";
import UserProfile from "./Auth/UserProfile";
import Application from "./Auth/DisplayApplication";
import UserDetails from './Auth/UserDetails';
import DisplayCourses from "./Auth/DisplayCourses";
import Accreditations from "./Auth/accreditations";
import Programs from "./Auth/Programs"
import DisplayPrograms from "./Auth/DisplayPrograms"


// import UserProfile from "./Auth/UserProfile";

// import EducationLoan from "./Auth/EducationLoan";

const NoRouteFound = () => <div>404</div>

function ProjectRouter(props) {

  return (
    <Router>
      <div>
        <Route
          history={props.history}
          path="/GraduatePrograms"
          component={GraduatePrograms}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/PartnerBanks"
          component={PartnerBanks}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/LoanDocuments"
          component={LoanDocuments}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Countries"
          component={Countries}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/EducationLoans"
          component={EducationLoans}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/CookiePolicy"
          component={CookiePolicy}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/UndergraduatePrograms"
          component={UndergraduatePrograms}
          strict
          exact
        ></Route>
        {/* <Route
          history={props.history}
          path="/OnlineProgram"
          component={OnlineProgram}
          strict
          exact
        ></Route> */}
        <Route
          history={props.history}
          path="/CertificateDiploma"
          component={CertificateDiploma}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Doctoral"
          component={Doctoral}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Affiliates"
          component={Affiliates}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/AdvertiseWithUs"
          component={AdvertiseWithUs}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Career"
          component={Career}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Careercounseling"
          component={Careercounseling}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/MissionVisionValues"
          component={MissionVisionValues}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/TestPreparation"
          component={TestPreparation}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/ScholarshipFooter"
          component={ScholarshipFooter}
          strict
          exact
        ></Route>

        <Route
          history={props.history}
          path="/"
          component={LandingPage}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Privacy"
          component={Privacy}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/TermsCondition"
          component={TermsCondition}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/LandingPage"
          component={LandingPage}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/SchoolDetails"
          component={SchoolDetails}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/AboutSection"
          component={AboutSection}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/CreateAccount"
          component={CreateAccount}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/SearchByCheckBox"
          component={SearchByCheckBox}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/OnlinePayment"
          component={OnlinePayment}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/ApplyProgram"
          component={ApplyProgram}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/SearchUniversity"
          component={SearchUniversity}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/CompareUniversity"
          component={CompareUniversity}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/CompareProgram"
          component={CompareProgram}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Scholarship"
          component={Scholarship}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Aboutus"
          component={Aboutus}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Blog"
          component={Blog}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Webinar"
          component={Webinar}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Contactus"
          component={Contactus}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/ApplyScholarship"
          component={ApplyScholarship}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/SearchCourse"
          component={SearchCourse}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/OpenBlog"
          component={OpenBlog}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Login"
          component={Login}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/ForgotPassword"
          component={ForgotPassword}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Compare"
          component={Compare}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/ProfileBuilder"
          component={ProfileBuilder}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/WhyChooses"
          component={WhyChooses}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/PressMedia"
          component={PressMedia}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/NewPaper"
          component={NewPaper}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Adminssion"
          component={Adminssion}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Selectuniversity"
          component={Selectuniversity}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Visa"
          component={Visa}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Traval"
          component={Traval}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Forex"
          component={Forex}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Appostile"
          component={Appostile}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Courier"
          component={Courier}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/InternationalSIM"
          component={InternationalSIM}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/UGCScholarships"
          component={UGCScholarships}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/CreditTransfer"
          component={CreditTransfer}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/FAQ"
          component={FAQ}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/General"
          component={General}
          strict
          exact
        ></Route>

        <Route
          history={props.history}
          path="/UK"
          component={UK}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Canada"
          component={Canada}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/NewZealand"
          component={NewZealand}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Ireland"
          component={Ireland}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/France"
          component={France}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Germany"
          component={Germany}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Australia"
          component={Australia}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Europe"
          component={Europe}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/USA"
          component={USA}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Singapore"
          component={Singapore}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Malaysia"
          component={Malaysia}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/UniversityDisplay"
          component={UniversityDisplay}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/CourseDiscription"
          component={CourseDiscription}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/SimilarProfile"
          component={SimilarProfile}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/IELTS"
          component={IELTS}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/TOEFL"
          component={TOEFL}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/PTE"
          component={PTE}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Duolingo"
          component={Duolingo}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/GRE"
          component={GRE}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/GMAT"
          component={GMAT}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/SAT"
          component={SAT}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/ACT"
          component={ACT}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/PersonalOnlineMentor"
          component={PersonalOnlineMentor}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/CProgram"
          component={CProgram}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/LoanProvider"
          component={LoanProvider}
          strict
          exact
        ></Route>

        <Route
          history={props.history}
          path="/CourseGuide"
          component={CourseGuide}
          strict
          exact
        ></Route>
        {/* <Route
          history={props.history}
          path="/Pricing"
          component={Pricing}
          strict
          exact
        ></Route> */}
        <Route
          history={props.history}
          path="/StudyAbroadAPI"
          component={StudyAbroadAPI}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/StudyAbroadInsight"
          component={StudyAbroadInsight}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Advising"
          component={Advising}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Intake"
          component={Intake}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Majors"
          component={Majors}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/TestPrep"
          component={TestPrep}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Pricing"
          component={Pricing}
          strict
          exact
        ></Route>
        {/* <Route
          history={props.history}
          path="/StudentProfile"
          component={StudentProfile}
          strict
          exact
        ></Route> */}
        <Route
          history={props.history}
          path="/Dashboard"
          component={LandingPage2}
          strict
          exact
        ></Route>

        <Route
          history={props.history}
          path="/UserProfile"
          component={UserProfile}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/Application"
          component={Application}
          strict
          exact
        ></Route>
        <Route
          history={props.history}
          path="/DisplayCourses"
          component={DisplayCourses}
          strict
          exact
        ></Route>

        <Route
          history={props.history}
          path="/accreditations"
          component={Accreditations}
          strict
          exact
        ></Route>

        <Route
          history={props.history}
          path="/UserDetails"
          component={UserDetails}
          strict
          exact
        ></Route>

        <Route
          history={props.history}
          path="/OnlinePrograms"
          component={Programs}
          strict
          exact
        ></Route>

        <Route
          history={props.history}
          path="/DisplayPrograms"
          component={DisplayPrograms}
          strict
          exact
        ></Route>
      {/*
        <Route component={LandingPage} />
        */}
      </div>
    </Router>
  );
}

export default ProjectRouter;
