import React, { Fragment } from "react";
import { Text, StyleSheet } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
  MDBSideNav,
  MDBNavItem,
} from "mdbreact";

//import {postDataAndImage,postData} from '../FetchServices';
import { Select, Checkbox } from "@material-ui/core";
import Selects from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MuiPhoneInput from "material-ui-phone-number";

import color from "@material-ui/core/colors/amber";
import Header from "./Header";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  navbarnav: {
    flexDirection: "row",
  },
  newbtn: {
    border: "none",
    position: "absolute",
    right: "5",
    top: "5",
    minHeight: 50,
    background: "#3f8ccb none repeat scroll 0 0",
    padding: "0 30",
    borderRadius: 30,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#ffffff",
    textTransform: "uppercase",
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
    fontSize: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "blue",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bigAvatar: {
    margin: "center",
    marginBottom: "10px",
    width: 60,
    height: 60,
    margin: theme.spacing(-1),
  },
  button: {
    margin: "center",
    marginTop: "10px",
  },
  Title: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10",
    marginBottom: "2em",
    backgroundColor: "#000",
    color: "#fff",
  },

  widths: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%",
  },
  bgdark: {
    backgroundColor: "#3f8ccb",
  },
  day: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },
  category: {
    position: "sticky",
  },
  category: {
    a: {
      display: "block",
    },
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [getpicturePath, setPicturePath] = React.useState("");
  const [getStatus, setStatus] = React.useState("");

  const handlePicture = (event) => {
    setPicturePath(URL.createObjectURL(event.target.files[0]));
    setPicture(event.target.files[0]);
  };

  const handleVerifyPassword = (e) => {
    setVerifyPassword(e.target.value);
    if (password === e.target.value) {
      setStatus("Password Matched");
    } else {
      setStatus("Password not matched");
    }
  };

  //   const addNewRecord=async()=>{
  // if(picture=='')
  // {
  //     var body={'firstName':firstName,
  //     'lastName':lastName,
  //     'emailId':emailId,
  //     'password':password,
  //     'status':"Not Approved",
  //     'picture':"Not Found"}
  //     var result=await postData('selfregister/addNewRecord',body)
  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}

  // }

  // else{
  //    var formData=new FormData()
  //    formData.append('firstName',firstName)
  //    formData.append('lastName',lastName)
  //    formData.append('emailId',emailId)
  //    formData.append('password',password)
  //    formData.append('picture',picture)
  //    formData.append('status', "Not Approve")

  //    var config={headers:{'content-type':'multipart/form-data'}}
  //     var result=await postDataAndImage('selfregister/addNewRecordPic',formData,config)

  //     if(result){
  //       await setMessages("Agent Succefully Created")

  //       var body={
  //         'emailid':emailId,
  //         'password':password
  //        }
  // var resultt = await  postData('selfregister/checkagentsignin',body)

  // if (resultt.RESULT)
  // {

  // localStorage.setItem('AGENT', JSON.stringify(resultt.RESULT)) // JSON.stringify use for change json to string
  // props.history.push({pathname:'/Dashboard'})  // we can use push(back) or replace(not back)
  // }
  // else
  // {
  // alert("Failed To Created Account")
  // }

  // await setFirstName('')
  // await setLastName('')
  // await setEmailId('')
  // await setPassword('')
  // await setVerifyPassword('')
  // await setPicturePath('')
  // }
  // else{setMessages("Faild to Submit")}
  //     }
  //     }

  console.log("History ==  ", props);
  return (
    <Container className={classes.widths}>
      <Header />
      <CssBaseline />
      <div
        className="login-area default-padding"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="fun-factor-area  default-padding slider-less text-light bg-fixed shadow dark-hard"
                style={{
                  backgroundImage: "url(" + "../assets/img/banner/25.jpg" + ")",
                  padding: 70,
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="double-items col-md-12 info">
                      <h2 className="wow fadeInLeft">Pricing</h2>
                    </div>
                  </div>
                </div>
              </div>

              <section class="membership_plan">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">

                            <div class="plan_main_box priceing">
                                <div class="plan_box">
                                    <ul>
                                        <li>
                                            <div class="plan_top"></div>
                                        </li>
                                        <li>
                                            <div class="plan_top_box">
                                                <div class="price_box">
                                                    <h3>Pricing</h3>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="plan_meta">
                                                Services
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Real time Admission Counselling
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Email+ Chat Support
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Essay
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               LOR's
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Scholarships
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Webinar
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Documents Evaulation
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Shortlisting Programs 
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                               Shortlisting University 
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Following up with university 
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Acceptance Letter
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Admission Letter (I20's/LOA)
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Scholarships
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Courier I20's/Admission Letter
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Partner university I20/ Admisison Letter
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Test Prep (IELTS/TOEFL/GRE/SAT/GMAT/PTE)
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Financing
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Assistance in Filling University Applications
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                One University Application Fee
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Education Loan
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Online Visa Training
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Embassy Visa Application
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Booking Visa Slot
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Mock Interview
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Phone Support
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Two University Application Fee
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Existing Students/ Delegates Talks
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Over 4000 Courses access till 1 year
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Communication and Personality Devlopment
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Ondemand Trainer
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                CA and Finance Report 
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Air Ticket Assistance
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Forex  Assistance
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Travel Insurance
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                One Time DS160 & SEVIS Payment
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               AirPort Pickup max 50 USD Refund
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                3 Days Accomdations in Host Country
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Job Assitance (Part time/Full time)
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Free Education in Europe*
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               Internships (Work and Study)
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Certificate Attestation & Apostille
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box odd_color">
                                               SM CashBack
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                Student Ambassador
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color blank_height">
                                              
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div class="plan_main_box">
                                <div class="plan_box basic">
                                    <ul>
                                        <li>
                                            <div class="plan_top"></div>
                                        </li>
                                        <li>
                                            <div class="plan_top_box plan_odd">
                                                <h4>Basic</h4>
                                                <div class="price_box basic_plan">
                                                    <h3>Application Fee only</h3>
                                                    <span></span>
                                                </div>
                                                
                                            </div>
                                        </li>
                                        <li>
                                            <div class="plan_meta font_0">
                                                Services
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                               <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                               <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                               <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <button class="btn_try apply-now" type="button" plan="Basic" price="50">Apply Now</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>


                            </div>
                            <div class="plan_main_box  advanced">
                                <div class="plan_box basic">
                                    <ul>
                                        <li>
                                            <div class="plan_top"></div>
                                        </li>
                                        <li>
                                            <div class="plan_top_box">
                                                <h4>Standard</h4>
                                                <div class="price_box">
                                                    <h3>$500</h3>
                                                    <span></span>
                                                </div>
                                                
                                            </div>
                                        </li>
                                        <li>
                                            <div class="plan_meta font_0">
                                                Services
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <button class="btn_try apply-now" plan="Standard" price="500" type="button">Apply Now</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>


                            </div>
                            <div class="plan_main_box">
                                <div class="plan_box basic Premium">
                                    <ul>
                                        <li>
                                            <div class="plan_top"></div>
                                        </li>
                                        <li>
                                            <div class="plan_top_box plan_odd">
                                                <h4>Professional </h4>
                                                <div class="price_box">
                                                    <h3>$1000</h3>
                                                    <span></span>
                                                </div>
                                                
                                            </div>
                                        </li>
                                        <li>
                                            <div class="plan_meta font_0">
                                                Services
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                         <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                               <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                               <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                               <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                               <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                               <i class="glyphicon glyphicon-minus"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <button class="btn_try apply-now" plan="Professional" price="1000" type="button">Apply Now</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>


                            </div>
                            <div class="plan_main_box Enterprise">
                                <div class="plan_box basic">
                                    <ul>
                                        <li>
                                            <div class="plan_top"></div>
                                        </li>
                                        <li>
                                            <div class="plan_top_box">
                                                <h4>Enterprise</h4>
                                                <div class="price_box">
                                                    <h3>$1500</h3>
                                                    <span></span>
                                                </div>
                                                
                                            </div>
                                        </li>
                                        <li>
                                            <div class="plan_meta font_0">
                                                Services
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box odd_color">
                                                 <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <i class="fa fa-check"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tb_box even_color">
                                                <button class="btn_try apply-now" plan="Enterprise" price="1500" type="button">Apply Now</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  );
}
